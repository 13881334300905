@tailwind base;
@tailwind components;
@tailwind utilities;

@import './variables';
// @import url('https://fonts.googleapis.com/css2?family=Ubuntu:wght@300;400;500;700&display=swap');



body {
  --width-scroll: 0px;
  margin          : 0;
  font-family     : $ff-nato-sans;
  color           : $color-black-blue;
  line-height     : normal;
  scroll-behavior : smooth;
  background-color: $color-black-default;

  &.no-scroll {
    overflow: hidden;
    padding-right: var(--width-scroll);
  }
}

button {
  outline: 0;
}

#root {
  overflow: hidden;

  .no-scroll & {
    padding-right: var(--width-scroll);
    margin-right: calc( -1 * var(--width-scroll));
  }
}

::-moz-selection {
  background-color: $color-accent;
}

::selection {
  background-color: $color-accent;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

p {
  white-space: pre-wrap;
}

.container {
  padding  : 0 100px;
  margin   : 0 auto;
  max-width: calc(#{$width-d}px - 100px);

  @include vp-n {
    max-width: 100%;
    padding: 0 size-n(100);
  }

  @include vp-d {
    padding: 0 size-d(32);
  }

  @include vp-t {
    padding: 0 size-t(20);
  }

  @include vp-m {
    padding: 0 size-m(16);
  }
}

.btn {
  display: inline-block;

  font-size  : 18px;
  font-weight: 400;

  padding      : 20px 40px;
  border-radius: 10px;

  text-align      : center;
  background-color: $color-purple;
  color           : $color-white;
  border          : 1px solid $color-purple;

  transition: $transition;

  &.btn--black {
    background-color: transparent;
    border          : 1px solid $color-purple;
  }

  &:hover {
    background-color: $color-purple-hover;
    border          : 1px solid $color-purple-hover;
  }

  &:active {
    background-color: $color-purple-active;
  }

  @include vp-n {
    font-size    : size-n(18);
    padding      : size-n(20) size-n(40);
    border-radius: size-n(10);
  }

  @include vp-d {
    font-size    : size-d(16);
    padding      : size-d(20) size-d(40);
    border-radius: size-d(10);
  }

  @include vp-t {
    font-size    : size-t(16);
    padding      : size-t(20) size-t(40);
    border-radius: size-t(10);
  }

  @include vp-m {
    font-size    : size-m(14);
    padding      : size-m(20) size-m(40);
    border-radius: size-m(10);
  }
}

.item-link {
  color      : $color-white;
  font-family: $ff-nato-sans;
  font-size  : 18px;
  font-style : normal;
  font-weight: 400;
  line-height: normal;
  text-decoration: underline;
  text-decoration-color: transparent;
  text-underline-offset: 5px;
  text-decoration-thickness: 2px;

  transition : text-decoration-color 0.1s linear;

  &:hover {
    text-decoration-color: $color-accent;
  }

  @include vp-n {
    font-size    : size-n(18);
    text-underline-offset: size-n(5);
    text-decoration-thickness: size-n(2);
  }

  @include vp-d {
    font-size    : size-d(16);
    text-underline-offset: size-d(4);
    text-decoration-thickness: size-d(2);
  }

  @include vp-t {
    font-size    : size-t(14);
    text-underline-offset: size-t(4);
    text-decoration-thickness: size-t(2);
  }

  @include vp-m {
    font-size    : size-m(14);
    text-underline-offset: size-m(4);
    text-decoration-thickness: size-m(2);
  }
}

a:not([class]) {
  text-decoration      : underline;
  text-decoration-color: transparent;
  text-underline-offset: 5px;
  transition           : text-decoration-color 0.1s linear, color 0.1s linear;
  color                : $color-accent;

  @include vp-n {
    text-underline-offset: size-n(5);
  }

  @include vp-d {
    text-underline-offset: size-d(4);
  }

  @include vp-t {
    text-underline-offset: size-t(4);
  }

  @include vp-m {
    text-underline-offset: size-m(4);
  }

  &:hover,
  &:focus {
    color                : $color-white;
    text-decoration-color: $color-accent;
    outline              : 0;
  }
}


[data-typed-animation] {
  --charsCursor: 1;
  color      : $color-white;
  font-family: $ff-bebas-neue;
  font-weight: 800;
  opacity: 0;

  display     : inline-block;
  white-space : nowrap;
  /* не переносить текст на другую строку */
  overflow    : hidden;
  /* скрываем переполнение контентом */
  border-right: 5px solid;
  width: fit-content;
  box-sizing: content-box;

  @include vp-n {
    border-right: size-n(5) solid;
  }

  @include vp-d {
    border-right: size-d(4) solid;
  }

  @include vp-t {
    border-right: size-t(4) solid;
  }

  @include vp-m {
    border-right: size-m(3) solid;
  }

  &.animation {
    opacity: 1;
    animation: cursor 0.3s step-end var(--charsCursor) forwards;
  }
}

@keyframes cursor {
  0% {
    border-color: transparent;
  }

  50% {
    border-color: white;
  }

  100% {
    border-color: transparent;
  }
}