@import '../../style/variables';

.about {
  padding      : 140px 0 170px;
  overflow     : hidden;
  margin-bottom: -120px;

  @include vp-n {
    padding: size-n(140) 0 170px;
  }

  @include vp-d {
    padding: size-d(100) 0 170px;
  }

  @include vp-t {
    padding: size-t(80) 0 120px;
  }

  @include vp-m {
    padding: size-t(50) 0 150px;
  }
}

.about__block {
  position    : relative;
  padding-left: 584px;

  @include vp-n {
    padding-left: size-n(584);
  }

  @include vp-d {
    padding-left: 0;
  }

  &::before {
    content            : '';
    position           : absolute;
    left               : -280px;
    width              : 800px;
    aspect-ratio       : 798/997;
    background-image   : url('./img/me.png');
    background-position: center;
    background-repeat  : no-repeat;
    background-size    : contain;
    z-index            : -1;

    bottom: -300px;

    @supports (background-image : url('./img/me.webp')) {
      background-image: url('./img/me.webp');
    }

    @include retina {
      background-image: url('./img/me@2x.png');

      @supports(background-image : url('./img/me@2x.webp')) {
        background-image : url('./img/me@2x.webp');
      }
    }

    @include vp-n {
      left : size-n(-280);
      width: size-n(800);
    }

    @include vp-d {
      left  : auto;
      right : size-d(-40);
      width : 47%;
      bottom: -200px;
    }

    @include vp-t {
      right : size-t(-40);
      bottom: -150px;
    }

    @include vp-m {
      display: none;
    }
  }
}

.about__title {
  color         : $color-white;
  font-family   : $ff-bebas-neue;
  font-size     : 58px;
  font-style    : normal;
  font-weight   : 400;
  line-height   : 100%;
  text-transform: uppercase;

  margin: 0 0 20px;

  @include vp-n {
    font-size: size-n(58);
    margin   : 0 0 size-n(20);
  }

  @include vp-d {
    max-width: size-d(660);
    font-size: size-d(32);
    margin   : 0 0 size-d(10);
  }

  @include vp-t {
    max-width: size-t(620);
    font-size: size-t(32);
    margin   : 0 0 size-t(10);
  }

  @include vp-m {
    max-width: 100%;
    font-size: size-m(26);
    margin   : 0 0 size-m(10);
  }
}

.about__description {
  color      : $color-white;
  font-family: $ff-nato-sans;
  font-size  : 18px;
  font-style : normal;
  font-weight: 400;
  line-height: normal;
  margin     : 0 0 40px;
  max-width  : 500px;

  @include vp-n {
    font-size: size-n(18);
    margin   : 0 0 size-n(40);
    max-width: size-n(500);
  }

  @include vp-d {
    font-size: size-d(16);
    margin   : 0 0 size-d(30);
    max-width: size-d(460);
  }

  @include vp-t {
    font-size: size-t(14);
    margin   : 0 0 size-t(30);
    max-width: size-t(460);
  }

  @include vp-m {
    font-size: size-m(14);
    margin   : 0 0 size-m(20);
    max-width: 100%;
  }
}

.about__list {
  display              : grid;
  grid-template-columns: 1fr 1fr;
  gap                  : 40px;
  margin               : 0 0 50px;

  list-style: none;
  padding   : 0;

  @include vp-n {
    gap   : size-n(40);
    margin: 0 0 size-n(50);
  }

  @include vp-d {
    gap   : size-d(40);
    margin: 0 0 size-d(30);
  }

  @include vp-t {
    gap   : size-t(20);
    margin: 0 0 size-t(30);
  }

  @include vp-m {
    grid-template-columns: 1fr;
    gap                  : size-m(20);
    margin               : 0 0 size-m(30);
  }
}

.about__item {
  &:last-of-type {
    @include vp-d {
      grid-column: 1/2;
    }
  }
}

.about__item-title {
  position    : relative;
  color       : $color-white;
  font-family : $ff-nato-sans;
  font-size   : 22px;
  font-style  : normal;
  font-weight : 700;
  line-height : normal;
  padding-left: 40px;

  margin: 0 0 15px;

  @include vp-n {
    font-size   : size-n(22);
    padding-left: size-n(40);
    margin      : 0 0 size-n(15);
  }

  @include vp-d {
    font-size   : size-d(20);
    padding-left: size-d(40);
    margin      : 0 0 size-d(10);
  }

  @include vp-t {
    font-size   : size-t(16);
    padding-left: size-t(40);
    margin      : 0 0 size-t(10);
  }

  @include vp-m {
    font-size   : size-m(16);
    padding-left: size-m(35);
    margin      : 0 0 size-m(7);
  }

  &:before {
    content  : '';
    position : absolute;
    left     : 0;
    top      : 50%;
    transform: translateY(-50%);

    display: block;

    background-position: center;
    background-size    : contain;
    background-repeat  : no-repeat;
  }

  &--time::before {
    width           : 30px;
    height          : 30px;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30' fill='none'%3E%3Cpath d='M15 26.25C21.2132 26.25 26.25 21.2132 26.25 15C26.25 8.7868 21.2132 3.75 15 3.75C8.7868 3.75 3.75 8.7868 3.75 15C3.75 21.2132 8.7868 26.25 15 26.25Z' stroke='white' stroke-width='2'/%3E%3Cpath d='M20.625 15H15.3125C15.2296 15 15.1501 14.9671 15.0915 14.9085C15.0329 14.8499 15 14.7704 15 14.6875V10.625' stroke='white' stroke-width='2' stroke-linecap='round'/%3E%3C/svg%3E");

    @include vp-n {
      width : size-n(30);
      height: size-n(30);
    }

    @include vp-d {
      width : size-d(30);
      height: size-d(30);
    }

    @include vp-t {
      width : size-t(30);
      height: size-t(30);
    }

    @include vp-m {
      width : size-m(25);
      height: size-m(25);
    }
  }

  &--value::before {
    width           : 20px;
    height          : 29px;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='22' height='32' viewBox='0 0 22 32' fill='none'%3E%3Cpath d='M9.82176 20.2416H7.31683C3.71141 20.2416 1.91107 20.2416 1.23004 19.0705C0.546664 17.8993 1.43741 16.3323 3.21891 13.2005L8.79669 3.38105C9.68037 1.82577 10.1234 1.04813 10.5617 1.16596C11 1.28143 11 2.17453 11 3.9631V10.5801C11 11.1362 11 11.4143 11.172 11.5863C11.344 11.7583 11.6221 11.7583 12.1782 11.7583H14.6832C18.2886 11.7583 20.0889 11.7583 20.77 12.9295C21.4533 14.1007 20.5626 15.6677 18.7811 18.7995L13.2033 28.6189C12.3196 30.1742 11.8766 30.9518 11.4383 30.834C11 30.7209 11 29.8278 11 28.0392V21.4199C11 20.8638 11 20.5857 10.828 20.4137C10.656 20.2416 10.3779 20.2416 9.82176 20.2416Z' stroke='white' stroke-width='1.8'/%3E%3C/svg%3E");

    @include vp-n {
      width : size-n(20);
      height: size-n(29);
    }

    @include vp-d {
      width : size-d(20);
      height: size-d(29);
    }

    @include vp-t {
      width : size-t(20);
      height: size-t(30);
    }

    @include vp-m {
      width : size-m(17);
      height: size-m(25);
    }
  }

  &--future::before {
    width           : 30px;
    height          : 30px;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30' fill='none'%3E%3Cpath d='M15.0048 0V2.30769C8.02981 2.30769 2.3125 8.025 2.3125 15C2.3125 21.975 8.02981 27.6923 15.0048 27.6923C21.9798 27.6923 27.6971 21.975 27.6971 15C27.6971 11.5996 26.4163 8.52692 24.1271 6.23769L22.5048 7.86115C24.3706 9.72462 25.3894 12.1685 25.3894 15C25.3894 20.7173 20.7221 25.3846 15.0048 25.3846C9.2875 25.3846 4.62019 20.7173 4.62019 15C4.62019 9.28269 9.2875 4.61538 15.0048 4.61538V6.92308L20.774 3.46154L15.0048 0ZM17.421 7.57269L14.5363 13.3419C14.1727 13.4408 13.8519 13.6568 13.6237 13.9566C13.3954 14.2564 13.2725 14.6232 13.274 15C13.274 15.459 13.4564 15.8993 13.781 16.2238C14.1056 16.5484 14.5458 16.7308 15.0048 16.7308H15.0775L18.7906 20.445L20.4498 18.7858L16.7356 15.0727V15C16.7356 14.7727 16.671 14.55 16.5913 14.3504L19.5117 8.58115L17.421 7.57154V7.57269Z' fill='white'/%3E%3C/svg%3E");

    @include vp-n {
      width : size-n(30);
      height: size-n(30);
    }

    @include vp-d {
      width : size-d(30);
      height: size-d(30);
    }

    @include vp-t {
      width : size-t(30);
      height: size-t(30);
    }

    @include vp-m {
      width : size-m(25);
      height: size-m(25);
    }
  }

  &--free::before {
    width           : 30px;
    height          : 30px;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30' fill='none'%3E%3Cg clip-path='url(%23clip0_181_1937)'%3E%3Cpath d='M15 21.25C18.4518 21.25 21.25 18.4518 21.25 15C21.25 11.5482 18.4518 8.75 15 8.75C11.5482 8.75 8.75 11.5482 8.75 15C8.75 18.4518 11.5482 21.25 15 21.25Z' stroke='white' stroke-width='2.3' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M15 1.25V3.75' stroke='white' stroke-width='2.3' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M15 26.25V28.75' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M5.27344 5.2749L7.04844 7.0499' stroke='white' stroke-width='2.3' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M22.9531 22.95L24.7281 24.725' stroke='white' stroke-width='2.3' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M1.25 15H3.75' stroke='white' stroke-width='2.3' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M26.25 15H28.75' stroke='white' stroke-width='2.3' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M5.27344 24.725L7.04844 22.95' stroke='white' stroke-width='2.3' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M22.9531 7.0499L24.7281 5.2749' stroke='white' stroke-width='2.3' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_181_1937'%3E%3Crect width='30' height='30' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E");

    @include vp-n {
      width : size-n(30);
      height: size-n(30);
    }

    @include vp-d {
      width : size-d(30);
      height: size-d(30);
    }

    @include vp-t {
      width : size-t(30);
      height: size-t(30);
    }

    @include vp-m {
      width : size-m(25);
      height: size-m(25);
    }
  }
}

.about__item-description {
  color      : $color-white;
  font-family: $ff-nato-sans;
  font-size  : 18px;
  font-style : normal;
  font-weight: 400;
  line-height: normal;
  margin     : 0;

  @include vp-n {
    font-size: size-n(18);
  }

  @include vp-d {
    font-size: size-d(16);
  }

  @include vp-t {
    font-size: size-t(14);
  }

  @include vp-m {
    font-size: size-m(14);
  }
}