@import '../../style/variables';


.skills {
  position: relative;
  overflow: hidden;
}

.skills__wrapper {
  background-color: $color-black;
  padding         : 110px 0;

  @include vp-n {
    padding: size-n(110) 0;
  }

  @include vp-d {
    padding: size-d(90) 0;
  }

  @include vp-t {
    padding: size-t(50) 0;
  }

  @include vp-m {
    padding: size-m(30) 0;
  }
}

.skills__title {
  font-size     : 130px;
  font-style    : normal;
  text-transform: uppercase;
  line-height   : normal;
  margin-bottom : 72px;

  @include vp-n {
    font-size    : size-n(130);
    border-right : size-n(5) solid;
    margin-bottom: size-n(72);
  }

  @include vp-d {
    font-size    : size-d(85);
    border-right : size-d(4) solid;
    margin-bottom: size-d(40);
  }

  @include vp-t {
    font-size    : size-t(75);
    border-right : size-t(4) solid;
    margin-bottom: size-t(40);
  }

  @include vp-m {
    font-size    : size-m(60);
    border-right : size-m(4) solid;
    margin-bottom: size-m(20);
  }
}

.skills__block {
  position             : relative;
  display              : grid;
  grid-template-columns: 1fr;
  grid-auto-rows       : auto;
  gap                  : 60px;

  @include vp-n {
    padding-right: size-n(60);
    gap          : size-n(60);
  }

  @include vp-d {
    padding-right: 0;
    gap          : size-d(40);
  }

  @include vp-t {
    gap: size-t(40);
  }

  @include vp-m {
    gap: size-m(30);
  }

  &::before {
    content : '';
    position: absolute;
    bottom  : -50px;
    right   : -150px;
    z-index : 2;

    display            : block;
    width              : 186px;
    aspect-ratio       : 186/176;
    background-image   : url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='188' height='180' viewBox='0 0 188 180' fill='none'%3E%3Cpath d='M30.9131 179H157.087C165.02 179 172.629 175.842 178.239 170.22C183.848 164.598 187 156.974 187 149.024V87.7518C187 79.8015 183.848 72.177 178.239 66.5553C172.629 60.9336 165.02 57.7753 157.087 57.7753H124.123L114.49 1L63.8774 57.7753H30.9131C22.9797 57.7753 15.3711 60.9336 9.76133 66.5553C4.15153 72.177 1 79.8015 1 87.7518V149.263C1.06314 157.172 4.24253 164.735 9.8455 170.305C15.4485 175.875 23.021 179 30.9131 179Z' fill='%235B5F97' stroke='%23263238' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M75 105.753L48.8091 115.97L75 125.886V134L40 120.177V111.823L75 98V105.753Z' fill='white'/%3E%3Cpath d='M103.425 78H112L86.5749 151H78L103.425 78Z' fill='white'/%3E%3Cpath d='M149 111.674V120.084L114 134V125.892L140.191 115.909L114 105.865V98L149 111.674Z' fill='white'/%3E%3C/svg%3E");
    background-position: center;
    background-size    : contain;
    background-repeat  : no-repeat;

    animation: animationTag 15s linear infinite;
    filter   : drop-shadow($shadow);

    @include vp-n {
      bottom: size-n(-50);
      right : size-n(-50);
      width : size-n(186);
    }

    @include vp-d {
      display   : none;
      // right  : size-d(70);
      // width  : size-d(110);
    }

    // @include vp-t {
    //   bottom: size-t(40);
    //   right : size-t(120);
    //   width : size-t(150);
    // }

    // @include vp-m {
    //   display: none;
    // }
  }
}

.skills__type {
  display        : flex;
  flex-direction : column;
  align-items    : flex-start;
  justify-content: flex-start;
}

.skills__type-title {
  color         : $color-white;
  font-family   : $ff-saar-sp-demo;
  font-size     : 60px;
  font-style    : normal;
  font-weight   : 400;
  line-height   : normal;
  text-transform: uppercase;
  margin-bottom : 60px;

  @include vp-n {
    font-size    : size-n(60);
    margin-bottom: size-n(60);
  }

  @include vp-d {
    font-size    : size-d(40);
    margin-bottom: size-d(30);
  }

  @include vp-t {
    font-size    : size-t(36);
    margin-bottom: size-t(20);
  }

  @include vp-m {
    font-size    : size-m(26);
    margin-bottom: size-m(20);
  }
}

.skills__list {
  list-style: none;
  padding   : 0;
  margin    : 0;

  width: 100%;

  display              : grid;
  grid-template-columns: repeat(3, 1fr);
  gap                  : 25px;

  @include vp-n {
    gap: size-n(25);
  }

  @include vp-d {
    grid-template-columns: repeat(2, 1fr);
    gap                  : size-d(20);
  }

  @include vp-t {
    gap: size-t(20);
  }

  @include vp-m {
    grid-template-columns: repeat(1, 1fr);
    gap                  : size-m(10);
  }
}

.skill {
  display              : grid;
  grid-template-columns: auto 1fr;
  gap                  : 40px;

  @include vp-n {
    gap: size-n(40);
  }

  @include vp-d {
    gap: size-d(20);
  }

  @include vp-t {
    gap: size-t(20);
  }

  @include vp-m {
    gap: size-m(20);
  }
}

.skill__title {
  display    : flex;
  align-items: center;

  color      : $color-white;
  font-family: $ff-nato-sans;
  font-size  : 22px;
  font-style : normal;
  font-weight: 700;
  line-height: normal;
  margin     : 0;

  @include vp-n {
    font-size: size-n(22);
  }

  @include vp-d {
    font-size: size-d(20);
  }

  @include vp-t {
    font-size: size-t(16);
  }

  @include vp-m {
    font-size: size-m(16);
  }
}

.skill-scale {
  --count : 15;
  --gap   : 2px;
  --border: 4px;

  position: relative;
  padding : 5px 3px;
  border  : 4px solid $color-white;
  width   : 250px;
  height  : 44px;

  display        : flex;
  flex-direction : row;
  align-items    : stretch;
  justify-content: flex-start;
  gap            : var(--gap);

  border-radius: 5px;
  overflow     : hidden;

  @include vp-n {
    padding      : size-n(5) size-n(3);
    border       : size-n(4) solid $color-white;
    width        : size-n(250);
    height       : size-n(44);
    border-radius: size-n(5);
  }

  @include vp-d {
    padding      : size-d(5) size-d(3);
    border       : size-d(4) solid $color-white;
    width        : size-d(160);
    height       : size-d(40);
    border-radius: size-d(5);
  }

  @include vp-t {
    padding      : size-t(5) size-t(3);
    border       : size-t(4) solid $color-white;
    width        : size-t(160);
    height       : size-t(40);
    border-radius: size-t(4);
  }

  @include vp-m {
    padding      : size-m(5) size-m(3);
    border       : size-m(4) solid $color-white;
    width        : size-m(160);
    height       : size-m(40);
    border-radius: size-m(4);
  }

  // & .skill-scale__pixels {
  //   $borderSize: 4px;
  //   position     : absolute;
  //   top          : -1 * ($borderSize + 1);
  //   bottom       : -1 * ($borderSize + 1);
  //   width        : $borderSize;
  //   border-top   : $borderSize solid $color-black;
  //   border-bottom: $borderSize solid $color-black;

  //   &--start {
  //     left: -1 * $borderSize;
  //   }

  //   &--end {
  //     right: -1 * $borderSize;
  //   }

  //   @include vp-n {
  //     $borderSize: size-n(6);
  //     top          : -1 * $borderSize;
  //     bottom       : -1 * $borderSize;
  //     width        : $borderSize;
  //     border-top   : $borderSize solid $color-black;
  //     border-bottom: $borderSize solid $color-black;

  //     &--start {
  //       left: -1 * $borderSize;
  //     }

  //     &--end {
  //       right: -1 * $borderSize;
  //     }
  //   }

  //   @include vp-d {
  //     $borderSize: size-d(6);
  //     top          : -1 * $borderSize;
  //     bottom       : -1 * $borderSize;
  //     width        : $borderSize;
  //     border-top   : $borderSize solid $color-black;
  //     border-bottom: $borderSize solid $color-black;

  //     &--start {
  //       left: -1 * $borderSize;
  //     }

  //     &--end {
  //       right: -1 * $borderSize;
  //     }
  //   }

  //   @include vp-t {
  //     $borderSize: size-t(6);
  //     top          : -1 * $borderSize;
  //     bottom       : -1 * $borderSize;
  //     width        : $borderSize;
  //     border-top   : $borderSize solid $color-black;
  //     border-bottom: $borderSize solid $color-black;

  //     &--start {
  //       left: -1 * $borderSize;
  //     }

  //     &--end {
  //       right: -1 * $borderSize;
  //     }
  //   }

  //   @include vp-m {
  //     $borderSize: size-m(5);
  //     top          : -1 * $borderSize;
  //     bottom       : -1 * $borderSize;
  //     width        : $borderSize;
  //     border-top   : $borderSize solid $color-black;
  //     border-bottom: $borderSize solid $color-black;

  //     &--start {
  //       left: -1 * $borderSize;
  //     }

  //     &--end {
  //       right: -1 * $borderSize;
  //     }
  //   }
  // }
}

.skill-scale__element {
  width           : calc((100% - (var(--count) - 1) * var(--gap)) / var(--count));
  height          : 100%;
  display         : block;
  background-color: $color-accent;
  opacity         : 0;

  .skills__block.animation & {
    animation: appear 400ms forwards;
  }
}

@keyframes appear {
  0% {
    opacity: 0;
  }

  50%,
  100% {
    opacity: 1;
  }
}