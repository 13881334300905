$ff-saar-sp-demo: 'Saar SP Demo', sans-serif;
$ff-nato-sans: 'Noto Sans', sans-serif;
$ff-bebas-neue: 'Bebas Neue', 'Bebas Neue Cyrillic', sans-serif;


$color-black-default: #2B2B2B;
$color-black: #343434;

$color-black-90: #343434ed;
$color-purple: #5B5F97;
$color-purple-hover: #515486;
$color-purple-active: #474A7C;

$color-accent: #FFCC00;

$color-black-blue: #1D1E33;
$color-white: #ffffff;

$color-gray: #757575;
$color-gray-50: #75757550;
$color-white-70: #ffffff70;

$shadow: 0px 5px 15px 5px rgba(0,0,0,0.1);
$shadow-text: 1px 1px 3px $color-black-blue; 
$transition: 0.4s ease-in-out;
$transition-min: 0.2s ease-in-out;

$width-d: 1920;
$width-t: 1024;
$width-m: 768;

$retina-dpi : 144dpi;
$retina-dppx: 1.5dppx;

@function size-n($width) {
  @return calc($width * 100vw / $width-d);
}

@function size-d($width) {
  @return calc($width * 100vw / $width-t);
}

@function size-t($width) {
  @return calc($width * 100vw / $width-m);
}

@function size-m($width) {
  @return calc($width * 100vw / 360);
}

// Desktop first

@mixin retina {
  @media (min-resolution: $retina-dpi), (min-resolution: $retina-dppx) {
    @content;
  }
}

@mixin vp-n {
  @media (max-width: 1920px) {
    @content;
  }
}

@mixin vp-d {
  @media (max-width: 1440px) {
    @content;
  }
}

@mixin vp-t {
  @media (max-width: 900px) {
    @content;
  }
}

@mixin vp-m {
  @media (max-width: 500px) {
    @content;
  }
}