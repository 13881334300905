@import '../../style/variables';


.banner {
  height    : 100vh;
  max-height: 900px;
  min-height: 750px;

  @include vp-n {
    max-height: size-n(900);
    min-height: size-n(750);
  }

  @include vp-d {
    max-height: none;
    min-height: none;
    height: size-d(550);
  }

  @include vp-t {
    height: size-t(440);
  }

  @include vp-m {
    height: size-m(390);
  }

  & .container {
    height: 100%;
  }
}

.banner__block {
  height         : 100%;
  display        : flex;
  flex-direction : column;
  align-items    : flex-start;
  justify-content: flex-end;

  background-image   : url('./img/computer.svg');
  background-position: 100% calc(100% - 220px);
  background-repeat  : no-repeat;
  background-size    : 80%;

  @include vp-n {
    background-position: 100% calc(100% - size-n(220));
  }

  @include vp-d {
    background-position: 100% calc(100% - size-d(120));
  }

  @include vp-t{
    background-position: 100% calc(100% - size-t(100));
  }

  @include vp-m {
    background-position: 100% calc(100% - size-m(120));
    background-size    : 130%;
  }
}


.banner__title {
  color         : $color-accent;
  font-family   : $ff-saar-sp-demo;
  font-size     : 150px;
  font-style    : normal;
  font-weight   : 400;
  line-height   : normal;
  text-transform: uppercase;

  @include vp-n {
    font-size: size-n(150);
  }

  @include vp-d {
    font-size: size-d(85);
  }

  @include vp-t {
    font-size: size-t(65);
  }

  @include vp-m {
    line-height: 70%;
    font-size: size-m(70);
    margin-bottom: size-m(-10);
  }
}

.banner__cybertitle {
  position: relative;
  
  @include vp-m {
    width: fit-content;
    font-size: size-m(28);
  }

  .banner__title-small {
    position: absolute;
    left: 101%;
    top: 70%;
    translate: 0 -100%;
    font-size: 0.3em;
    line-height: 100%;
    white-space: nowrap;

    &.animation {
      opacity: 0.5;
    }

    @include vp-m {
      top: 57%;
      font-size: 0.4em;
    }
  }
}

