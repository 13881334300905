@import '../../style/variables';

.popup {
  position: fixed;
  inset   : 0;

  display        : flex;
  flex-direction : column;
  justify-content: center;
  align-items    : center;

  background-color: $color-gray-50;
  opacity         : 0;
  transition      : opacity $transition-min;
  z-index         : 1000;

  &.open {
    opacity: 1;
  }
}

.popup__wrapper {
  display        : flex;
  flex-direction : column;
  align-items    : stretch;
  justify-content: flex-start;

  position        : relative;
  width           : 100%;
  max-width       : 1250px;
  max-height      : 95vh;
  background-color: $color-black;
  padding         : 80px;
  border-radius   : 5px;
  transition      : scale $transition-min;
  scale           : 0;

  .open & {
    scale: 1;
  }

  @include vp-n {
    padding      : size-n(80);
    border-radius: size-n(5);
  }

  @include vp-d {
    max-width    : size-d(900);
    padding      : size-d(50);
    border-radius: size-d(5);
  }

  @include vp-t {
    max-width    : size-t(640);
    padding      : size-t(40);
    border-radius: size-t(5);
  }

  @include vp-m {
    max-width    : 100%;
    max-height   : 100%;
    height       : auto;
    padding      : size-m(25) size-m(16);
    border-radius: size-m(5);
  }
}

.popup__close-btn {
  position: absolute;
  top     : 80px;
  right   : 80px;

  display: block;
  width  : 43px;
  height : 43px;

  cursor: pointer;

  background-position: center center;
  background-repeat  : no-repeat;
  background-size    : contain;
  background-image   : url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='43' height='43' viewBox='0 0 43 43' fill='none'%3E%3Cpath d='M38.0857 43L21.5 26.4143L4.91429 43L0 38.0857L16.5857 21.5L0 4.91429L4.91429 0L21.5 16.5857L38.0857 0L43 4.91429L26.4143 21.5L43 38.0857L38.0857 43Z' fill='%23E8E8E3'/%3E%3C/svg%3E");

  transition: $transition-min;
  scale     : 1;

  &:hover {
    scale: 1.1;
  }

  @include vp-n {
    top   : size-n(80);
    right : size-n(80);
    width : size-n(43);
    height: size-n(43);
  }

  @include vp-d {
    top   : size-d(50);
    right : size-d(50);
    width : size-d(35);
    height: size-d(35);
  }

  @include vp-t {
    top   : size-t(40);
    right : size-t(40);
    width : size-t(30);
    height: size-t(30);
  }

  @include vp-m {
    top   : size-m(25);
    right : size-m(16);
    width : size-m(30);
    height: size-m(30);
  }

}

.popup__title {
  font-family  : $ff-bebas-neue;
  color        : $color-white;
  font-size    : 60px;
  font-weight  : 400;
  line-height  : 120%;
  margin-bottom: 10px;
  padding-right: 50px;

  @include vp-n {
    font-size    : size-n(60);
    margin-bottom: size-n(10);
    padding-right: size-n(50);
  }

  @include vp-d {
    font-size    : size-d(40);
    margin-bottom: size-d(10);
    padding-right: size-d(40);
  }

  @include vp-t {
    font-size    : size-t(36);
    margin-bottom: size-t(5);
    padding-right: size-t(40);
  }

  @include vp-m {
    font-size    : size-m(26);
    margin-bottom: size-m(10);
    padding-right: size-m(30);
  }
}

.popup__description {
  font-family  : $ff-nato-sans;
  color        : $color-white;
  font-size    : 18px;
  font-weight  : 400;
  line-height  : 140%;
  margin-bottom: 30px;
  max-width    : 70%;

  @include vp-n {
    font-size    : size-n(18);
    margin-bottom: size-n(30);
  }

  @include vp-d {
    max-width    : 80%;
    font-size    : size-d(16);
    margin-bottom: size-d(30);
  }

  @include vp-t {
    max-width    : 90%;
    font-size    : size-t(14);
    margin-bottom: size-t(10);
  }

  @include vp-m {
    max-width    : 100%;
    font-size    : size-m(14);
    margin-bottom: size-m(15);
  }
}

.popup__stack,
.popup__tools {
  font-family: $ff-nato-sans;
  color      : $color-white;
  font-weight: 400;
  max-width  : 70%;

  font-size    : 18px;
  line-height  : 25px;
  margin-bottom: 10px;

  @include vp-n {
    font-size    : size-n(18);
    line-height  : size-n(25);
    margin-bottom: size-n(10);
  }

  @include vp-d {
    max-width    : 80%;
    font-size    : size-d(16);
    line-height  : size-d(21);
    margin-bottom: size-d(10);
  }

  @include vp-t {
    max-width    : 90%;
    font-size    : size-t(14);
    line-height  : size-t(19);
    margin-bottom: size-t(5);
  }

  @include vp-m {
    max-width    : 100%;
    font-size    : size-m(14);
    line-height  : size-m(19);
    margin-bottom: size-m(5);
  }
}

.popup__text-title {
  font-family: $ff-nato-sans;
  color      : $color-white;
  font-weight: 800;
  font-size  : 22px;
  line-height: 30px;

  @include vp-n {
    font-size  : size-n(22);
    line-height: size-n(30);
  }

  @include vp-d {
    font-size  : size-d(20);
    line-height: size-d(27);
  }

  @include vp-t {
    font-size  : size-t(18);
    line-height: size-t(24);
  }

  @include vp-m {
    font-size  : size-m(16);
    line-height: size-m(21);
  }
}

.popup__feachers-text {
  display       : flex;
  flex-direction: row;
  align-items   : center;

  position     : relative;
  font-family  : $ff-nato-sans;
  color        : $color-white;
  font-weight  : 700;
  margin-top   : 30px;
  margin-bottom: 20px;
  font-size    : 22px;
  line-height  : 30px;

  @include vp-n {
    margin-top   : size-n(30);
    margin-bottom: size-n(20);
    font-size    : size-n(22);
    line-height  : size-n(30);
  }

  @include vp-d {
    margin-top   : size-d(30);
    margin-bottom: size-d(20);
    font-size    : size-d(20);
    line-height  : size-d(27);
  }

  @include vp-t {
    margin-top   : size-t(20);
    margin-bottom: size-t(10);
    font-size    : size-t(16);
    line-height  : size-t(22);
  }

  @include vp-m {
    margin-top   : size-m(15);
    margin-bottom: size-m(10);
    font-size    : size-m(16);
    line-height  : size-m(22);
  }

  &::before {
    content            : '';
    display            : block;
    width              : 30px;
    height             : 30px;
    margin-right       : 5px;
    background-repeat  : no-repeat;
    background-size    : contain;
    background-position: center;
    background-image   : url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30' fill='none'%3E%3Cpath d='M3.75 15H5M15 3.75V5M25 15H26.25M7 7L7.875 7.875M23 7L22.125 7.875M12.125 21.25H17.875M11.25 20C10.2006 19.2129 9.42542 18.1156 9.03429 16.8635C8.64316 15.6114 8.65591 14.268 9.07073 13.0236C9.48555 11.7791 10.2814 10.6967 11.3456 9.92974C12.4097 9.16273 13.6882 8.75 15 8.75C16.3118 8.75 17.5903 9.16273 18.6544 9.92974C19.7186 10.6967 20.5145 11.7791 20.9293 13.0236C21.3441 14.268 21.3568 15.6114 20.9657 16.8635C20.5746 18.1156 19.7994 19.2129 18.75 20C18.262 20.4831 17.8945 21.0743 17.6773 21.7258C17.4602 22.3773 17.3994 23.0707 17.5 23.75C17.5 24.413 17.2366 25.0489 16.7678 25.5178C16.2989 25.9866 15.663 26.25 15 26.25C14.337 26.25 13.7011 25.9866 13.2322 25.5178C12.7634 25.0489 12.5 24.413 12.5 23.75C12.6006 23.0707 12.5398 22.3773 12.3227 21.7258C12.1055 21.0743 11.738 20.4831 11.25 20Z' stroke='white' stroke-width='2.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");

    @include vp-n {
      width       : size-n(30);
      height      : size-n(30);
      margin-right: size-n(5);
    }

    @include vp-d {
      width       : size-d(30);
      height      : size-d(30);
      margin-right: size-d(5);
    }

    @include vp-t {
      width       : size-t(30);
      height      : size-t(30);
      margin-right: size-t(5);
    }

    @include vp-m {
      width       : size-m(30);
      height      : size-m(30);
      margin-right: size-m(5);
    }
  }
}

.popup__feachers-list {
  display        : flex;
  flex-direction : column;
  align-items    : flex-start;
  justify-content: flex-start;
  gap            : 10px;
  margin-bottom  : 30px;

  @include vp-n {
    gap          : size-n(10);
    margin-bottom: size-n(30);
  }

  @include vp-d {
    gap          : size-d(10);
    margin-bottom: size-d(30);
  }

  @include vp-t {
    max-width    : 80%;
    gap          : size-t(5);
    margin-bottom: size-t(20);
  }

  @include vp-m {
    max-width    : 100%;
    gap          : size-m(5);
    margin-bottom: size-m(20);
  }
}

.popup__feachers-item {
  position    : relative;
  font-family : $ff-nato-sans;
  color       : $color-white;
  font-weight : 400;
  font-size   : 18px;
  line-height : 25px;
  padding-left: 29px;

  @include vp-n {
    font-size   : size-n(18);
    line-height : size-n(25);
    padding-left: size-n(29);
  }

  @include vp-d {
    font-size   : size-d(16);
    line-height : size-d(22);
    padding-left: size-d(29);
  }

  @include vp-t {
    font-size   : size-t(14);
    line-height : size-t(19);
    padding-left: size-t(29);
  }

  @include vp-m {
    font-size   : size-m(14);
    line-height : size-m(19);
    padding-left: size-m(29);
  }


  &::before {
    content            : '';
    display            : block;
    position           : absolute;
    top                : 0;
    left               : 0;
    width              : 24px;
    height             : 24px;
    background-position: center;
    background-repeat  : no-repeat;
    background-size    : contain;
    background-image   : url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'%3E%3Cpath d='M9.55156 18.0001L3.85156 12.3001L5.27656 10.8751L9.55156 15.1501L18.7266 5.9751L20.1516 7.4001L9.55156 18.0001Z' fill='white'/%3E%3C/svg%3E");

    @include vp-n {
      width : size-n(24);
      height: size-n(24);
    }

    @include vp-d {
      width    : size-d(24);
      height   : size-d(24);
      top      : 0%;
      translate: 0 0;
    }

    @include vp-t {
      width : size-t(24);
      height: size-t(24);
    }

    @include vp-m {
      width : size-m(24);
      height: size-m(24);
    }
  }
}

.popup__btns {
  display        : flex;
  flex-direction : row;
  align-items    : center;
  justify-content: flex-start;
  flex-wrap      : wrap;
  gap            : 20px;
  margin-bottom  : 40px;

  @include vp-n {
    gap          : size-n(20);
    margin-bottom: size-n(40);
  }

  @include vp-d {
    gap          : size-d(20);
    margin-bottom: size-d(40);
  }

  @include vp-t {
    gap          : size-t(20);
    margin-bottom: size-t(30);
  }

  @include vp-m {
    gap          : size-m(20);
    margin-bottom: size-m(20);

    .btn {
      padding: size-m(15) size-m(25);
    }
  }
}

.popup__slide {
  // &--mobile.swiper-slide {
  //   display: none;
  // }

  &--desktop.swiper-slide {
    display: block;
  }

  @include vp-m {
    &--mobile.swiper-slide {
      display: block;
    }

    &--desktop.swiper-slide {
      display: none;
    }
  }
}

.popup__slider-imgs {
  position: relative;
  height: 400px;

  @include vp-n {
    height: size-n(400);
  }

  @include vp-d {
    height: size-d(400);
  }

  @include vp-t {
    height: size-t(350);
  }

  @include vp-m {
    height: auto;

    &.swiper {
      padding-bottom: size-m(50);
    }
  }

  .swiper-slide {
    width: auto;
    max-width: 100%;
  }
}


.swiper-pagination-bullet {
  background: $color-purple;
  opacity: 1;

  @include vp-m {
    width: size-m(10);
    height: size-m(10);
  }

  &.swiper-pagination-bullet-active {
    background: $color-white;
  }
}

.popup__slider-controlls {
  position: absolute;

  top  : -93px;
  right: 0;

  display        : flex;
  flex-direction : row;
  align-items    : center;
  justify-content: flex-end;
  gap            : 20px;

  @include vp-n {
    top: size-n(-93);
    gap: size-n(20);
  }

  @include vp-d {
    top: size-d(-80);
    gap: size-d(10);
  }

  @include vp-t {
    top: size-t(-80);
    gap: size-t(20);
  }

  @include vp-m {
    display: none;
  }
}

.popup__arrow {
  display            : block;
  width              : 40px;
  height             : 40px;
  background-position: center center;
  background-repeat  : no-repeat;
  background-size    : contain;
  background-image   : url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='50' height='50' viewBox='0 0 50 50' fill='none'%3E%3Cpath d='M36.6894 0.40039C37.1093 0.40039 37.5391 0.566405 37.8613 0.888671C38.5059 1.5332 38.5059 2.58789 37.8613 3.23242L15.8008 25.293L37.5391 47.0313C38.1836 47.6758 38.1836 48.7305 37.5391 49.375C36.8945 50.0195 35.8398 50.0195 35.1953 49.375L12.2754 26.4648C11.6309 25.8203 11.6309 24.7656 12.2754 24.1211L35.5078 0.888671C35.8398 0.55664 36.2597 0.40039 36.6894 0.40039Z' fill='white'/%3E%3C/svg%3E");
  scale              : 1;
  transition         : $transition-min;
  transform-origin   : right center;

  @include vp-n {
    width : size-n(40);
    height: size-n(40);
  }

  @include vp-d {
    width : size-d(30);
    height: size-d(30);
  }

  @include vp-t {
    width : size-t(30);
    height: size-t(30);
  }

  @include vp-m {
    width : size-m(25);
    height: size-m(25);
  }

  &:hover {
    scale: 1.1;
  }

  &:disabled {
    opacity: 0.3;
  }

  &--left {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='50' height='50' viewBox='0 0 50 50' fill='none'%3E%3Cpath d='M36.6894 0.40039C37.1093 0.40039 37.5391 0.566405 37.8613 0.888671C38.5059 1.5332 38.5059 2.58789 37.8613 3.23242L15.8008 25.293L37.5391 47.0313C38.1836 47.6758 38.1836 48.7305 37.5391 49.375C36.8945 50.0195 35.8398 50.0195 35.1953 49.375L12.2754 26.4648C11.6309 25.8203 11.6309 24.7656 12.2754 24.1211L35.5078 0.888671C35.8398 0.55664 36.2597 0.40039 36.6894 0.40039Z' fill='white'/%3E%3C/svg%3E");
  }

  &--right {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='50' height='50' viewBox='0 0 50 50' fill='none'%3E%3Cpath d='M13.3106 0.40039C12.8907 0.40039 12.4609 0.566405 12.1387 0.888671C11.4941 1.5332 11.4941 2.58789 12.1387 3.23242L34.1992 25.293L12.4609 47.0313C11.8164 47.6758 11.8164 48.7305 12.4609 49.375C13.1055 50.0195 14.1602 50.0195 14.8047 49.375L37.7246 26.4648C38.3691 25.8203 38.3691 24.7656 37.7246 24.1211L14.4922 0.888671C14.1602 0.55664 13.7403 0.40039 13.3106 0.40039Z' fill='white'/%3E%3C/svg%3E");
  }
}


.popup__img {
  display: block;
  height: 100%;
  width : auto;
  object-fit: contain;
  max-height: 100%;
  max-width : 100%;

  object-fit: contain;

  overflow     : hidden;
  border-radius: 20px;


  @include vp-m {
    width: 100%;
    height : auto;
  }

  img {
    height: 100%;
    object-fit: contain;

    @include vp-m {
      width: 100%;
      height : auto;
    }
  }
}

.popup__scroll-content {
  position: relative;

  .scroll-content {
    padding-right: 30px;

    @include vp-n {
      padding-right: size-n(30);
    }

    @include vp-d {
      padding-right: size-d(25);
    }

    @include vp-t {
      padding-right: size-t(15);
    }

    @include vp-m {
      padding-right:  size-m(10);
    }
  }


  .scrollbar-track-x {
    display: none !important;
  }

  .scrollbar-track-y {
    right        : 16px;
    width        : 8px;
    border-radius: 5px;

    @include vp-n {
      right        : size-n(16);
      width        : size-n(8);
      border-radius: size-n(5);
    }

    @include vp-d {
      right        : size-d(12);
      width        : size-d(8);
      border-radius: size-d(5);
    }

    @include vp-t {
      right        : size-t(10);
      width        : size-t(8);
      border-radius: size-t(5);
    }

    @include vp-m {
      right        : 0;
      width        : size-m(8);
      border-radius: size-m(5);
    }
  }

  .scrollbar-track {
    opacity: 0.6;
    background: $color-black-default;
  }

  .scrollbar-thumb {
    background: $color-purple;
  }
}


.popup__imgs-block {
  position: relative;

  // .swiper-slide {
  //   padding: 0 10px;

  //   @include vp-n {
  //     padding: 0 size-n(10);
  //   }

  //   @include vp-d {
  //     padding: 0 size-d(10);
  //   }

  //   @include vp-t {
  //     padding: 0 size-t(8);
  //   }

  //   @include vp-m {
  //     padding: 0;
  //   }
  // }

  &::before,
  &::after {
    // content         : '';
    z-index         : 2;
    display         : block;
    position        : absolute;
    top             : 0;
    bottom          : 0;
    right           : 0;
    width           : 10px;
    background-image: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, $color-black 100%);

    @include vp-n {
      width: size-n(10);
    }

    @include vp-d {
      width: size-d(10);
    }

    @include vp-t {
      width: size-t(8);
    }

    @include vp-m {
      content: none;
    }
  }

  &::after {
    right           : auto;
    left            : 0;
    background-image: linear-gradient(-90deg, rgba(255, 255, 255, 0) 0%, $color-black 100%);
  }
}