@import '../../style/variables';

.header {
  position   : absolute;
  top        : 0;
  left       : 0;
  right      : 0;
  opacity    : 1;
  padding-top: 100px;

  transition: top $transition-min, opacity $transition-min;

  @include vp-n {
    padding-top: size-n(100);
  }

  @include vp-d {
    padding-top: size-d(60);
  }

  @include vp-t {
    // position: fixed;
    // background-color: $color-black-90;
    // border-bottom   : size-t(3) solid $color-purple-hover;
    // z-index: 50;
    padding: size-t(30) 0;
    height : size-t(90);
  }

  @include vp-m {
    // border-bottom   : size-m(3) solid $color-purple-hover;
    padding: size-m(20) 0;
    height : size-m(60);
  }

  &.header--fixed {
    position: fixed;

    top    : 0;
    left   : 0;
    right  : 0;
    z-index: 50;
    opacity: 1;

    padding            : 40px 0;
    background-color   : $color-black-90;
    // border-bottom   : 5px solid $color-purple-hover;

    @include vp-n {
      padding               : size-n(40) 0;
      // border-bottom-width: size-n(5);
    }

    @include vp-d {
      padding               : size-d(30) 0;
      // border-bottom-width: size-d(4);
    }

    @include vp-t {
      padding               : size-t(30) 0;
      // border-bottom-width: size-t(4);
    }

    @include vp-m {
      padding               : size-m(20) 0;
      // border-bottom-width: size-m(2);
    }

    &.header--open_menu {
      background-color: transparent;
    }

    .no-scroll & {
      padding-right: var(--width-scroll);
    }
  }
}

.header__block {
  display        : flex;
  flex-direction : row;
  align-items    : flex-start;
  justify-content: space-between;

  position: relative;
}

.header-nav__list {
  list-style: none;
  padding   : 0;
  margin    : 0;

  display        : flex;
  flex-direction : column;
  align-items    : flex-start;
  justify-content: flex-start;

  gap: 30px;

  @include vp-n {
    gap: size-n(30);
  }

  @include vp-d {
    gap: size-d(20);
  }

  @include vp-t {
    gap: size-t(20);
  }

  @include vp-m {
    gap: size-m(20);
  }
}

.lang__header {
  --count-lng    : 3;
  position       : absolute;
  right          : 0;
  top            : 0;
  z-index        : 100;
  display        : flex;
  flex-direction : column;
  align-items    : center;
  justify-content: flex-start;

  overflow: hidden;

  border-radius: 500px;
  border       : 1px solid $color-purple;
  transition   : border-color $transition-min, height $transition-min, background-color $transition-min, width $transition-min;

  height: 75px;

  @include vp-n {
    border-radius: size-n(500);
    height       : size-n(75);
  }

  @include vp-d {
    border-radius: size-d(500);
    height       : size-d(58);
  }

  @include vp-t {
    top           : 50%;
    translate     : 0 -50%;
    flex-direction: row-reverse;
    border-radius : size-t(500);
    height        : size-t(55);
    width         : size-t(55);
  }

  @include vp-m {
    top          : 50%;
    translate    : 0 -50%;
    border-radius: size-m(500);
    height       : size-m(45);
    width        : size-m(45);
  }

  &.hover {
    // background-color: $color-black;
    height: calc(75px * var(--count-lng));

    @include vp-n {
      height: calc(size-n(75) * var(--count-lng));
    }

    @include vp-d {
      height: calc(size-d(58) * var(--count-lng));
    }

    @include vp-t {
      height: size-t(55);
      width : calc(size-t(55) * var(--count-lng));
    }

    @include vp-m {
      height: size-m(45);
      width : calc(size-m(45) * var(--count-lng));
    }
  }

  .header--fixed & {
    border-color  : $color-purple;
    top           : 50%;
    translate     : 0 calc(-75px/2);
    flex-direction: row-reverse;
    width         : 75px;
    height        : 75px;

    @include vp-n {
      translate: 0 calc(size-n(-75)/2);
      height   : size-n(75);
      width    : size-n(75);
    }

    @include vp-d {
      translate: 0 calc(size-d(-56)/2);
      height   : size-d(56);
      width    : size-d(56);
    }

    @include vp-t {
      top      : 50%;
      translate: 0 -50%;
      height   : size-t(55);
      width    : size-t(55);
    }

    @include vp-m {
      top      : 50%;
      translate: 0 -50%;
      height   : size-m(45);
      width    : size-m(45);
    }

    &.hover {
      height: 75px;
      width : calc(75px * var(--count-lng));

      @include vp-n {
        height: size-n(75);
        width : calc(size-n(75) * var(--count-lng));
      }

      @include vp-d {
        height: size-d(58);
        width : calc(size-d(58) * var(--count-lng));
      }

      @include vp-t {
        height: size-t(55);
        width : calc(size-t(55) * var(--count-lng));
      }

      @include vp-m {
        height: size-m(45);
        width : calc(size-m(45) * var(--count-lng));
      }
    }
  }
}

.lang__btn {
  display        : flex;
  align-items    : center;
  justify-content: center;

  position   : relative;
  min-width      : 75px;
  max-width: 75px;
  aspect-ratio: 1/1;
  z-index    : 3;
  color      : $color-white;
  font-family: $ff-nato-sans;
  font-size  : 18px;
  font-style : normal;
  font-weight: 400;
  line-height: normal;

  border-radius: 50%;
  transition: all $transition-min;

  @include vp-n {
    min-width    : size-n(75);
    max-width    : size-n(75);
    font-size: size-n(18);
  }

  @include vp-d {
    min-width    : size-d(58);
    max-width    : size-d(58);
    font-size: size-d(18);
  }

  @include vp-t {
    min-width    : size-t(55);
    max-width    : size-t(55);
    font-size: size-t(14);
  }

  @include vp-m {
    min-width    : size-m(45);
    max-width    : size-m(45);
    font-size: size-m(13);
  }

  .hover &:hover {
    background-color: $color-purple;
    border-color    : transparent;
  }

  // &.current {
  //   pointer-events: none;
  // }
}

.header--fixed .header-nav__list {
  display       : flex;
  flex-direction: row;

  @include vp-t {
    flex-direction: column;
  }
}

.header__btn {
  display: none;

  @include vp-t {
    position       : relative;
    z-index        : 51;
    display        : flex;
    flex-direction : column;
    align-items    : stretch;
    justify-content: space-between;
    overflow       : hidden;

    width : size-t(40);
    height: size-t(25);
  }

  @include vp-m {
    width : size-m(40);
    height: size-m(20);
  }

  .line {
    display         : block;
    width           : 100%;
    height          : 1px;
    opacity         : 1;
    transition      : opacity $transition-min, transform $transition;
    transform-origin: top left;
    background-color: $color-white;
  }

  &.open {
    .line:first-of-type {
      transform: rotate(45deg);
    }

    .line:nth-of-type(2) {
      opacity: 0;
    }

    .line:last-of-type {
      transform: rotate(-45deg);
    }
  }
}

.header-nav {
  @include vp-t {
    position        : fixed;
    z-index         : 40;
    // top: size-t(90);
    top             : 0;
    bottom          : 0;
    left            : -110vw;
    right           : 110vw;
    background-color: $color-black-90;

    padding   : size-t(95) size-t(60) size-t(30) size-t(25);
    transition: left $transition-min, right $transition-min;
  }

  @include vp-m {
    // top: size-m(60);
    padding: size-m(65) size-m(60) size-m(30) size-m(20);
  }

  &.show {
    left : 0;
    right: 0;
  }
}