@import '../../style/variables';

.portfolio {
  background-color: $color-black;
  position        : relative;
  overflow        : hidden;
  cursor          : auto;

  .no-scroll & {
    padding-right: var(--width-scroll);
    margin-right : calc(-1 * var(--width-scroll));
  }
}

.portfolio__wrapper {
  padding: 110px 0;

  @include vp-n {
    padding: size-n(110) 0;
  }

  @include vp-d {
    padding: size-d(90) 0;
  }

  @include vp-t {
    padding: size-t(50) 0;
  }

  @include vp-m {
    padding: size-m(30) 0;
  }
}

.portfolio__title {
  font-size     : 130px;
  font-style    : normal;
  text-transform: uppercase;
  line-height   : normal;
  margin-bottom : 72px;

  @include vp-n {
    font-size    : size-n(130);
    border-right : size-n(5) solid;
    margin-bottom: size-n(72);
  }

  @include vp-d {
    font-size    : size-d(85);
    border-right : size-d(4) solid;
    margin-bottom: size-d(40);
  }

  @include vp-t {
    font-size    : size-t(75);
    border-right : size-t(4) solid;
    margin-bottom: size-t(30);
  }

  @include vp-m {
    font-size    : size-m(60);
    border-right : size-m(2) solid;
    margin-bottom: size-m(20);
  }
}

.portfolio__slider {
  position: relative;
  overflow: visible;

  display              : grid;
  grid-template-columns: repeat(3, 1fr);
  gap                  : 40px;

  @include vp-n {
    gap: size-n(40);
  }

  @include vp-d {
    gap: size-d(20);
  }

  @include vp-t {
    gap                  : size-t(20);
    grid-template-columns: 1fr;
  }

  @include vp-m {
    gap: size-m(20);
  }
}

.portfolio__slide {
  position        : relative;
  display         : flex;
  flex-direction  : column;
  align-items     : flex-start;
  justify-content : flex-start;
  background-color: $color-purple;

  user-select: none;
  height     : auto;

  &::after {
    content          : '';
    display          : block;
    position         : absolute;
    z-index          : 1;
    background-size  : contain;
    background-repeat: no-repeat;
  }

  &:nth-child(4n + 1) {
    grid-column: 2/4;
    grid-row: auto;
    width      : 100%;

    .portfolio__slide-block {
      padding-right: 60%;

      @include vp-d {
        padding-right: 50%;
      }

      @include vp-m {
        padding-right: 15%;
      }
    }

    .portfolio__slide-block::before {
      max-height: 100%;
      max-width : 55%;

      @include vp-d {
        max-width : 60%;
        max-height: 100%;
      }

      @include vp-t {
        max-height: 90%;
      }

      @include vp-m {
        left: 5%;
        right: 5%;
        max-width : 90%;
        max-height: size-m(150);
      }
    }

    @include vp-t {
      grid-column: auto;
    }

    &::after {
      width           : 80px;
      top             : -1px;
      right           : -1px;
      aspect-ratio    : 88/98;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='88' height='98' viewBox='0 0 87 100' fill='none'%3E%3Crect y='22' width='26' height='54' fill='%23343434' x='1'/%3E%3Crect x='78' y='0' width='52' height='25' fill='%23343434'/%3E%3Crect x='54' y='46' width='100' height='25' fill='%23343434'/%3E%3Crect x='25' y='0' width='25' height='25' fill='%23343434'/%3E%3Crect x='26' y='22' width='104' height='25' fill='%23343434'/%3E%3C/svg%3E");

      @include vp-n {
        width: size-n(80);
      }

      @include vp-d {
        width: size-d(60);
      }

      @include vp-t {
        width: size-t(45);
      }

      @include vp-m {
        width: size-m(45);
      }
    }
  }

  &:nth-child(4n + 2) {
    grid-column: 1/3;
    grid-row: auto;
    width      : 100%;

    .portfolio__slide-block {
      padding-right: 50%;

      @include vp-d {
        padding-right: 40%;
      }

      @include vp-t {
        padding-right: 50%;
      }

      @include vp-m {
        padding-right: 15%;
      }

    }

    .portfolio__slide-block::before {
      max-height: 100%;
      max-width : 60%;
      height    : 100%;

      @include vp-d {
        max-width: 50%;
      }

      @include vp-t {
        max-height: 90%;
      }

      @include vp-m {
        max-width : 100%;
        max-height: size-m(150);
      }
    }

    @include vp-t {
      grid-column: auto;
    }

    &::after {
      width           : 120px;
      bottom          : -1px;
      left            : -1px;
      aspect-ratio    : 130/52;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='130' height='52' viewBox='0 0 130 52' fill='none'%3E%3Crect x='104' y='52' width='25' height='25' transform='rotate(-180 104 52)' fill='%23343434'/%3E%3Crect x='79' y='27' width='25' height='25' transform='rotate(-180 79 27)' fill='%23343434'/%3E%3Crect x='54' y='52' width='54' height='25' transform='rotate(-180 54 52)' fill='%23343434'/%3E%3Crect x='131' y='52' width='26' height='52' transform='rotate(-180 130 52)' fill='%23343434'/%3E%3C/svg%3E");

      @include vp-n {
        width: size-n(120);
      }

      @include vp-d {
        width: size-d(100);
      }

      @include vp-t {
        width: size-t(65);
      }

      @include vp-m {
        width: size-m(65);
        bottom: auto;
        left: auto;
        top: -1px;
        right: -1px;
        transform: rotateX(180deg);
      }
    }
  }

  &:nth-child(1) {
    grid-column: 1/4;

    @include vp-t {
      grid-column: auto;
    }
  }
}

.portfolio__slide-block {
  width  : 100%;
  height : 100%;
  padding: 60px 35px 200px 60px;

  @include vp-n {
    padding: size-n(60) size-n(35) size-n(200) size-n(60);
  }

  @include vp-d {
    padding: size-d(30) size-d(40) size-d(150) size-d(30);
  }

  @include vp-t {
    padding: size-t(30) 50% size-t(50) size-t(30);
  }

  @include vp-m {
    padding: size-m(30) size-m(40) size-m(160) size-m(30);
  }

  &::before {
    content   : '';
    position  : absolute;
    bottom    : 0;
    right     : 0;
    width     : 100%;
    height    : 100%;
    max-height: 190px;

    background-position: bottom center;
    background-repeat  : no-repeat;
    background-size    : contain;
    background-image   : var(--img);

    @supports (background-image: var(--imgWebp)) {
      background-image: var(--imgWebp)
    }

    @include vp-n {
      max-height: size-n(190);
    }

    @include vp-d {
      max-height: size-d(150);
    }

    @include vp-t {
      width     : 50%;
      max-height: 90%;
    }

    @include vp-m {
      width     : 100%;
      max-height: size-m(150);
    }
  }
}

.portfolio__slide-title {
  font-family  : $ff-nato-sans;
  font-size    : 22px;
  font-weight  : 700;
  line-height  : 145%;
  color        : $color-white;
  margin-bottom: 10px;

  @include vp-n {
    font-size    : size-n(22);
    margin-bottom: size-n(10);
  }

  @include vp-d {
    font-size    : size-d(16);
    margin-bottom: size-d(10);
  }

  @include vp-t {
    font-size    : size-t(16);
    margin-bottom: size-t(10);
  }

  @include vp-m {
    font-size    : size-m(16);
    margin-bottom: size-m(10);
  }
}

.portfolio__slide-description {
  font-family  : $ff-nato-sans;
  font-size    : 16px;
  font-weight  : 400;
  line-height  : 145%;
  color        : $color-white;
  margin-bottom: 20px;
  max-width    : 80%;

  @include vp-n {
    font-size    : size-n(16);
    margin-bottom: size-n(20);
  }

  @include vp-d {
    font-size    : size-d(14);
    margin-bottom: size-d(15);
    max-width    : 90%;
  }

  @include vp-t {
    font-size    : size-t(13);
    margin-bottom: size-t(15);
    max-width    : 100%;
  }

  @include vp-m {
    font-size    : size-m(13);
    margin-bottom: size-m(15);
  }
}

.portfolio__slide-btn {
  padding         : 20px 40px;
  border          : 1px solid $color-white;
  border-radius   : 20px;
  font-family     : $ff-nato-sans;
  font-size       : 18px;
  font-weight     : 400;
  line-height     : 145%;
  color           : $color-white;
  scale           : 1;
  transform-origin: top left;
  transition      : $transition-min;

  @include vp-n {
    padding      : size-n(20) size-n(40);
    border-radius: size-n(20);
    font-size    : size-n(18);
  }

  @include vp-d {
    padding      : size-d(15) size-d(30);
    border-radius: size-d(20);
    font-size    : size-d(16);
  }

  @include vp-t {
    padding      : size-t(15) size-t(30);
    border-radius: size-t(20);
    font-size    : size-t(14);
  }

  @include vp-m {
    padding      : size-m(15) size-m(30);
    border-radius: size-m(20);
    font-size    : size-m(14);
  }

  &:hover {
    scale           : 1.02;
    background-color: $color-purple-hover;
  }
}