@import '../../style/variables';

.pixels__block {
  position: relative;
  overflow: hidden;
  width   : 110%;
  height  : 20px;
}

.pixels__item {
  background-color: $color-black;
  width           : 20px;
  aspect-ratio    : 1/1;
  float           : left;
  opacity         : 1;
  animation       : blink 8s infinite;
  animation-play-state: paused;

  .pixels.up .pixels__block:nth-last-of-type(1) &,
  .pixels.down .pixels__block:nth-of-type(1) & {
    animation       : blink_min 15s infinite;
  }

  .pixels.up .pixels__block:nth-of-type(1) &,
  .pixels.down .pixels__block:nth-last-of-type(1) & {
    animation       : blink_max 15s infinite;
  }

  .pixels.up .pixels__block:nth-last-of-type(2) &,
  .pixels.down .pixels__block:nth-of-type(2) & {
    animation       : blink_min 10s infinite;
  }

  .pixels.up .pixels__block:nth-of-type(2) &,
  .pixels.down .pixels__block:nth-last-of-type(2) & {
    animation       : blink_max 10s infinite;
  }

  .show-animation & {
    animation-play-state: running;
  }
}

@keyframes blink_min {
  0%, 30% {
    opacity: 1;
  }
  
  50% {
    opacity: 0;
  }

  70%, 100% {
    opacity: 1;
  }
}

@keyframes blink_max {
  0%, 30% {
    opacity: 0;
  }
  
  50% {
    opacity: 1;
  }

  70%, 100% {
    opacity: 0;
  }
}

@keyframes blink {
  0% {
    opacity: 1;
  }

   100% {
    opacity: 0;
  }
}

