@import '../../style/variables';

.btn-up {
  position: fixed;
  z-index: 2;
  right: 40px;
  bottom: -100px;
  padding: 20px;
  border-radius: 50%;
  border: 2px solid $color-purple;
  transition: bottom $transition, opacity $transition, transform $transition;
  transform: scale(0);
  opacity: 0;
  background-color: $color-black-default;
  box-shadow: $shadow;

  @include vp-n {
    right: size-n(40);
    bottom: -size-n(100);
    padding: size-n(20);
    border-width: size-n(2);
  }

  @include vp-d {
    right: size-d(30);
    padding: size-d(15);
  }

  @include vp-t {
    right: size-t(20);
    padding: size-t(15);
  }

  @include vp-m {
    border-width: 1px;
    right: size-m(30);
    padding: size-m(10);
  }

  & svg {
    & path {
      fill: $color-white;
    }

    @include vp-m {
      width: size-m(20);
      height: size-m(20);
    }
  }

  &.btn-up--show {
    bottom: 40px;
    opacity: 1;
    transform: scale(1);

    @include vp-n {
      bottom: size-n(40);
    }

    @include vp-d {
      bottom: size-d(30);
    }

    @include vp-t {
      bottom: size-t(20);
    }

    @include vp-m {
      bottom: size-m(30);
    }
  }

  .no-scroll & {
    margin-right: calc( 1 * var(--width-scroll));
  }

  &:hover {
    background-color: $color-purple-hover;
    border-color: transparent;
  }
}